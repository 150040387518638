<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="p-3">
        <b-row>
          <b-col lg="2">
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Territory"
                size="sm"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <table-custom
          ref="dataTable"
          :name="`custom_table_territories`"
          :loading="dataTable.isLoading"
          :data="dataTable.dataSet"
          :options="dataTable.options"
        >
          <div slot="custom-actions" slot-scope="props">
            <div class="btn-group">
              <span class="btn btn-success btn-sm" @click="editItem(props.row)">
                <font-awesome-icon icon="pencil-alt" />
              </span>
              <span
                class="btn btn-danger btn-sm"
                @click="deleteItem(props.row)"
              >
                <font-awesome-icon icon="trash" />
              </span>
            </div>
          </div>
        </table-custom>
      </b-container>
    </b-card>

    <b-modal
      ref="modal-form"
      class="territory-modal"
      centered
      hide-footer
      size="lg"
      :title="modalForm.title"
    >
      <b-overlay
        :show="modalForm.isLoading"
        :opacity="0.5"
        spinner-variant="secondary"
        rounded="sm"
        z-index="1000"
      >
        <b-card class="territory-card">
          <b-card-body>
            <b-form>
              <b-form-group
                id="input-group-1"
                label="Territory name"
                label-for="input-1"
                description
              >
                <b-form-input
                  id="input-1"
                  v-model="modalForm.name"
                  type="text"
                  required
                  placeholder
                />
              </b-form-group>
            </b-form>
            <b-container
              :style="$isMobile ? 'height: 65vh;overflow: auto' : ''"
            >
              <b-row class="treeselect-container text-center">
                <b-col cols="12" lg="6" class="pt-2">
                  <span class="">Select places</span>
                  <div :class="$isMobile ? 'h-400' : 'h-800'">
                    <treeselect
                      class="treeselect"
                      v-model="modalForm.placesTree.value"
                      :multiple="true"
                      :always-open="true"
                      open-direction="bottom"
                      :options="modalForm.placesTree.options"
                    />
                  </div>
                </b-col>

                <b-col cols="12" lg="6" class="pt-2">
                  <span>Select users</span>
                  <div :class="$isMobile ? 'h-400' : 'h-800'">
                    <treeselect
                      class="treeselect"
                      v-model="modalForm.usersTree.value"
                      :multiple="true"
                      :always-open="true"
                      open-direction="bottom"
                      :options="modalForm.usersTree.options"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </b-card>
      </b-overlay>
      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="saveModal()">
          <font-awesome-icon icon="save" />&nbsp;Save
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" />&nbsp;Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import TableCustom from "@/components/TableCustom";

import { FORM_MODE } from "@/shared/constants";

import {
  getAscendingBranchAsArray,
  getTreeLeafByName,
  geoArrayToTree
} from "@/shared/data-permissions-helpers";

export default {
  name: "TerritoriesBuilder",
  components: {
    TableCustom,
    Treeselect
  },
  data: function() {
    return {
      FORM_MODE: FORM_MODE,
      rawDistricts: [],
      modalForm: {
        isLoading: false,
        id: null,
        name: "",
        mode: null,
        placesTree: {
          value: null,
          options: null
        },
        usersTree: {
          value: null,

          options: []
        }
      },
      dataTable: {
        isLoading: false,
        dataSet: [],
        options: {
          columns: ["Name", "Updated", "Created", "Created by", "Actions"],
          footerHeadings: false,
          perPage: 50,
          showCustomActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      this.onModalShow(bvEvent, modalId);
    });

    this.dataTable.isLoading = true;

    this.getData();

    this.getGeoMapping();

    this.getUsers();
  },
  methods: {
    getUsers() {
      let self = this;

      this.$api.get("dictionaries/users").then(response => {
        self.modalForm.usersTree.options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      });
    },
    getGeoMapping() {
      let self = this;

      this.$api
        .post("dictionaries/countries/mapped", {
          ignore_restrictions: true
        })
        .then(response => {
          self.modalForm.placesTree.options = geoArrayToTree(response);
        });
    },

    onAddClick() {
      /*
            let root = [];

            this.modalForm.placesTree.value.forEach(v => {

                let arr = getAscendingBranchAsArray(this.modalForm.placesTree.options, v, 0, {});

                let selectedBranch = getAscendingBranch(this.modalForm.placesTree.options, v);

                let existingBranch = root.find(n => n.id === selectedBranch.id);

                if (existingBranch) {
                    let m = mergeBranches(existingBranch, selectedBranch);

                    //replace old branch with merged one
                    let idx = root.findIndex(i => i.id === m.id);
                    root[idx] = m;
                } else {
                    root.push(selectedBranch);
                }
            });

            this.modalForm.destinationTree.options = root;
            */
    },
    onRemoveClick() {
      /*
            for (let el in this.modalForm.destinationTree.value) {}

            this.modalForm.destinationTree.options = this.modalForm.destinationTree.options.filter(
                i => !this.modalForm.destinationTree.value.includes(i.id)
            );

            this.modalForm.destinationTree.value = [];
            */
    },
    async getData() {
      let self = this;

      let response = [];

      try {
        response = await this.$api.get("territories");

        self.dataTable.isLoading = false;

        if (response.length === 0) return;

        self.dataTable.dataSet = response;
      } catch (err) {
        self.dataTable.isLoading = false;

        self.$form.msgBoxOk(err.message);
      }
    },
    closeModal() {
      this.modalForm.mode = undefined;
      this.$refs["modal-form"].hide();
    },
    async saveModal() {
      if (!this.modalForm.name) {
        await this.$form.msgBoxOk("Please enter name of the territory");
        return false;
      }

      if (!this.modalForm.placesTree.value.length) {
        await this.$form.msgBoxOk("Please select at least one place");
        return false;
      }

      let data = [];

      this.modalForm.placesTree.value.forEach(v => {
        let row = getAscendingBranchAsArray(
          this.modalForm.placesTree.options,
          v,
          0,
          {}
        );

        data.push(row);
      });

      let payload = {
        name: this.modalForm.name,
        data: data,
        users: this.modalForm.usersTree.value
      };

      this.modalForm.isLoading = true;

      let method = "",
        url = "";

      if (this.modalForm.mode === FORM_MODE.CREATE) {
        method = "post";
        url = "territories";
      }
      if (this.modalForm.mode === FORM_MODE.EDIT) {
        method = "put";
        url = `territories/${this.modalForm.id}`;
      }

      let self = this;

      this.$api[method](url, payload)
        .then(response => {
          self.modalForm.isLoading = false;

          if (response.message && response.message.errorInfo) {
            throw Error(response.message.errorInfo.join(";"));
          }

          self.modalForm.mode = undefined;

          self.$refs["modal-form"].hide();

          self.getData();
        })
        .catch(response => {
          self.modalForm.isLoading = false;

          //self.$form.makeToastError(response.message);
          self.$form.msgBoxOk(response.message);
        });
    },
    async onModalShow() {
      if (this.modalForm.mode === FORM_MODE.CREATE) {
        return true;
      }

      let id = this.modalForm.id;

      this.modalForm.placesTree.value = [];

      this.modalForm.usersTree.value = [];

      this.modalForm.isLoading = true;

      let error, places;
      [error, places] = await this.$to(this.$api.get(`territories/${id}`));
      if (error) {
        await this.$form.msgBoxOk(error.message);
        return;
      }

      //let places = await this.$api.get(`territories/${id}`)

      places.forEach(item => {
        //find in the tree, get id, add to selected values
        let leaf = {};

        if (item.city) {
          leaf = getTreeLeafByName(
            this.modalForm.placesTree.options,
            item.city
          );
        }
        if (!item.city && item.state) {
          leaf = getTreeLeafByName(
            this.modalForm.placesTree.options,
            item.state
          );
        }
        if (!item.city && !item.state && item.country) {
          leaf = getTreeLeafByName(
            this.modalForm.placesTree.options,
            item.country
          );
        }

        if (leaf.id) {
          //add unique

          if (!this.modalForm.placesTree.value.find(i => i === leaf.id)) {
            this.modalForm.placesTree.value.push(leaf.id);
          }
        }
      });

      let users;
      [error, users] = await this.$to(this.$api.get(`territories/${id}/users`));
      if (error) {
        await this.$form.msgBoxOk(error.message);
        return;
      }

      //let users = await this.$api.get(`territories/${id}/users`)

      users.forEach(item => {
        if (!this.modalForm.usersTree.value.find(i => i === item.user_id)) {
          this.modalForm.usersTree.value.push(item.user_id);
        }
      });

      this.modalForm.isLoading = false;
    },
    addItem() {
      this.modalForm.mode = FORM_MODE.CREATE;
      this.modalForm.id = "";
      this.modalForm.name = "";
      this.modalForm.placesTree.value = [];
      this.modalForm.usersTree.value = [];

      this.$refs["modal-form"].show();
    },
    editItem(item) {
      this.modalForm.mode = FORM_MODE.EDIT;

      this.modalForm.id = item["ID"];
      this.modalForm.name = item["Name"];

      this.$refs["modal-form"].show();
    },
    async deleteItem(item) {
      let confirm = await this.$form.showConfirmation(
        `Territory "${item.Name}" will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        i => i.ID !== item.ID
      );

      this.$api
        .delete(`territories/${item.ID}`)
        .then(() => {})
        .catch(response => {
          self.$form.makeToastError(response.message);

          this.getData();
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
.h-400 {
  height: 400px;
}
.h-800 {
  height: 600px;
}

::v-deep .vue-treeselect__menu {
  /*max-height: 300px !important;*/
}
/*
::v-deep .vue-treeselect__menu {
  max-height: 40vh !important;
}
*/
.treeselect-container >>> .vue-treeselect__multi-value {
  height: 100px !important;
  overflow: auto;
}
</style>
